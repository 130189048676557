import { Box, Breakpoint, Container, css } from "@mui/material";
import { BackgroundWithTexture } from "modules/picasso-ui/layout/BackgroundGradient"
import { ReactNode } from "react";
import { FC, PropsWithChildren } from "react";
import { AppLogoSmall } from "../component/AppLogoSmall"

const styles = {
    contentCont: css({
        position: 'relative',
        paddingBottom: '80px',
        display: 'flex',
        flexDirection: 'column',
    }),
    bottomElemCont: theme=>css({
        position: 'absolute',
        bottom: '0px',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingBottom: theme.spacing(2),
    }),
}

export interface CenteredContentScaffoldProps {
    disableBackground?: boolean
    fullHeight?: boolean
    bottomElem?: ReactNode
    showAppLogo?: boolean
    logoCentered?: boolean
    maxWidth?: false | Breakpoint
}

export const CenteredContentScaffold: FC<PropsWithChildren<CenteredContentScaffoldProps>> = ({children, disableBackground, fullHeight, bottomElem, showAppLogo, logoCentered, maxWidth}) => {

    return (
        <>
            {disableBackground !== true && <BackgroundWithTexture />}
            <div css={[styles.contentCont, fullHeight === true ? {minHeight:'100vh'}:null]}>
                {showAppLogo !== false &&
                    <Box pl={3} pr={3} pt={2} display="flex" justifyContent={logoCentered ? 'center':null}>
                        <AppLogoSmall />
                    </Box>
                }
                <Container 
                    maxWidth={maxWidth || 'sm'}
                    sx={{
                        flexGrow: 1, 
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" flexGrow={1} pt="15vh">
                        {children}
                    </Box>
                </Container>
                <div css={styles.bottomElemCont}>{bottomElem && <>{bottomElem}</>}</div>
            </div>
        </>
    )

}